<template>
    <b-row>
        <b-col cols="12">
            <b-card>
                <b-card-body>
                    <b-row>
                        <b-col class="mb-1" cols="4">Nama Bisnis</b-col>
                        <b-col cols="8">{{ companyName }}</b-col>

                        <b-col cols="4">Jenis Bisnis</b-col>
                        <b-col cols="8">{{ companyTypeNameID }}</b-col>
                    </b-row>
                    <hr>
                    
                    <validation-observer
                        ref="step1"
                    >
                        <b-form
                            class="auth-login-form"
                            @submit.prevent="step1"
                            >
                            <b-row>
                                <b-col class="mb-1" cols="12">
                                    <!-- basic -->
                                    <b-form-group
                                        label="Nama Campaign"
                                        label-for="project-name"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="Nama Proyek"
                                            vid="project-name"
                                            rules="required"
                                            :custom-messages="customMessages"
                                            >
                                            <b-form-input
                                                id="project-name"
                                                name="project-name"
                                                v-model="project_name"
                                                :state="errors.length > 0 ? false:null"
                                                placeholder="Masukan Nama Proyek"
                                                />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>

                                <b-col cols="12" class="mb-1">
                                    <small>Masukan cover campaign kamu semenarik mungkin!</small>
                                    <validation-provider
                                        #default="{ errors }"
                                        name="Cover Campaign"
                                        vid="cover-campaign"
                                        rules="required"
                                        :custom-messages="customMessages"
                                        >
                                        <vue-dropzone ref="myVueDropzone" id="dropzone" :options="dropzoneOptions" @vdropzone-success="uploadedFile"></vue-dropzone>
                                        <small>Format harus .jpg atau .png, dengan size maksimal 1MB</small>
                                        <input type="hidden" name="cover-campaign" :value="banner">
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-col>

                                <b-col cols="12">
                                    <!-- basic -->
                                    <b-form-group
                                        label="Apa Yang Kamu Promosikan"
                                        label-for="basicInput"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="Pilihan Promosi"
                                            vid="promo"
                                            rules="required"
                                            :custom-messages="customMessages"
                                            >
                                            <b-form-select
                                                id="promo"
                                                name="promo"
                                                v-model="selectedPromo"
                                                :options="optionsPromo"
                                                :state="errors.length > 0 ? false:null"
                                                />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>

                                <b-col class="mb-1" cols="12">
                                    <!-- basic -->
                                    <b-form-group
                                        label="Jumlah Follower"
                                        label-for="basicInput"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="Jumlah Follower"
                                            vid="follower"
                                            rules="required"
                                            :custom-messages="customMessages"
                                            >
                                            <b-form-select
                                                id="follower"
                                                name="follower"
                                                :state="errors.length > 0 ? false:null"
                                                v-model="selectedFollower"
                                                :options="optionsFollower"
                                                />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>

                                <b-col class="mb-1" cols="12">
                                    <b-row>
                                        <b-col cols="6">
                                            <b-row>
                                                <b-col cols="1" class="pt-3">
                                                    <input type="radio" name="tipe_project" v-model="campaign_type" value="Project" class="mb-1">
                                                </b-col>
                                                <b-col cols="3">
                                                    <h3>
                                                        <b-icon-people-fill style="width: 100px; height: 100px;"></b-icon-people-fill>
                                                    </h3>
                                                </b-col>
                                                <b-col cols="6">
                                                    <p>
                                                        Project
                                                    </p>
                                                    <p style="text-align: justify">
                                                        Semua influencer bisa melihat campaign dan berpartisipasi
                                                    </p>
                                                </b-col>
                                            </b-row>
                                        </b-col>
                                        <b-col cols="6">
                                            <b-row>
                                                <b-col cols="1" class="pt-3">
                                                    <input type="radio" name="tipe_project" v-model="campaign_type" value="Assistant" class="mb-1">
                                                </b-col>
                                                <b-col cols="3">
                                                    <h3>
                                                        <b-icon-person-plus-fill style="width: 100px; height: 100px;"></b-icon-person-plus-fill>
                                                    </h3>
                                                </b-col>
                                                <b-col cols="6">
                                                    <p>
                                                        Assistant
                                                    </p>
                                                    <p style="text-align: justify">
                                                        Hanya influencer yang diundang dapat melihat dan berpartisipasi
                                                    </p>
                                                </b-col>
                                            </b-row>
                                        </b-col>
                                    </b-row>
                                </b-col>

                                <b-col cols="12">
                                    <b-button
                                        type="submit"
                                        block
                                        variant="primary"
                                        >
                                        Selanjutnya
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-form>
                    </validation-observer>
                </b-card-body>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import {
    BRow,
    BCol,
    BCard,
    BButton,
    BCardBody,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormRadioGroup,
    BFormInvalidFeedback,
    BForm,
    BImg,
    BIconPeopleFill,
    BIconPersonPlusFill
} from 'bootstrap-vue'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import axios from '@axios'
export default {
    name: 'CampaignCreateStep1',
    components: {
        vueDropzone: vue2Dropzone,

        BRow,
        BCol,
        BCard,
        BButton,
        BCardBody,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BFormRadioGroup,
        BFormInvalidFeedback,
        BForm,
        BImg,
        BIconPeopleFill,
        BIconPersonPlusFill,        
        ValidationProvider,
        ValidationObserver,
    },
    data: function () {
        return {
            dropzoneOptions: {
                url: 'https://api-digirans.demoapp.xyz/advertiser/campaigns/basic-info/upload-banner',
                thumbnailWidth: 150,
                maxFilesize: 1,
                maxFiles: 1,
                headers: { "Authorization": "Bearer " + localStorage.getItem('accessToken') },
                thumbnailWidth: 200,
                addRemoveLinks: true,
                dictDefaultMessage: "<i class='fa fa-cloud-upload'></i>Klik atau arahkan file ke area ini untuk mengunggah"
            },

            selectedPromo: null,
            optionsPromo: [
                { value: null, text: '- Pilih Jenis yang Dipromosikan -' },
                { value: 1, text: 'Produk' },
                { value: 2, text: 'Event' },
                { value: 3, text: 'Kompetisi' }
            ],

            selectedFollower: null,
            optionsFollower: [
                { value: null, text: '- Pilih Jumlah Follower -' }
            ],

            optionsRadio: [
                { text: 'Project', value: 'Project' },
                { text: 'Assistant', value: 'Assistant' }
            ],
            valueRadio: null,
            state: true,

            project_name: null,
            campaign_type: null,
            banner: null,
            // validation rules
            required,
            email,

            companyName: null,
            companyTypeNameID: null,

            customMessages: {
                required: 'Harus diisi'
            }
        }
    },
    mounted() {
        let _ = this
        let userData = JSON.parse(localStorage.getItem('userData'))
        
        _.companyName = userData.advertiser.companyName
        _.companyTypeNameID = userData.advertiser.companyTypeName.name

        _.getRange()

        if ( _.$route.name == 'campaign-edit-1' || _.$route.params.id) {
            _.getCampaignData()
        }
    },
    methods: {        
        step1() {
            let _ = this
            this.$refs.step1.validate().then(success => {
                if (success) {
                    let _ = this
                    let nextPage = _.campaign_type == 'Project' ? 'step-2-project' : 'step-2-assistant'
                    let data = {
                        name: _.project_name,
                        banner: _.banner,
                        promotionTypeID: _.selectedPromo,
                        categoryID: _.selectedFollower,
                        campaignType: _.campaign_type
                    }

                    if ( _.$route.name == 'campaign-edit-1' ) {
                        data = {
                            ...data,
                            id: parseInt(localStorage.getItem('campaignID'))
                        }
                    }

                    axios.post('/advertiser/campaigns/basic-info', data)
                        .then(resp => {
                            localStorage.setItem('campaignID', resp.data.data.id)
                            localStorage.setItem('campaignType', resp.data.data.campaignType)
                            if ( _.$route.name == 'campaign-edit-1' ) {
                                let nextPage = _.campaign_type == 'Project' ? 'campaign-edit-2-project' : 'campaign-edit-2-assistant'
                                _.$router.push({name: nextPage, params: {id: _.$route.params.id}})
                            } else {
                                _.$router.push(nextPage)
                            }
                        })
                        .catch(err => {
                            console.log(err.response)
                        })
                } else {
                    console.log('failed validation')
                }
            })
        },
        getRange() {
            axios.get('/misc/types/categories/available-price')
                .then(resp => {
                    let _ = this
                    console.log(resp.data.data)
                    resp.data.data.map((v,i) => {
                        return {
                            value: v.id,
                            text: v.start + ' - ' + v.end + ' ('+v.name+')'
                        }
                    }).forEach((v,i) => {
                        _.optionsFollower.push(v)
                    })
                })
                .catch(err => {
                    console.log(err.response)
                })
        },
        uploadedFile(file, response) {
            let _ = this
            console.log(file)
            console.log(response.data.banner)
            _.banner = response.data.banner
        },
        getCampaignData() {
            let _ = this
            axios.get('/advertiser/campaigns/' + _.$route.params.id)
                .then(resp => {
                    let _ = this
                    let campaign = resp.data.data
                    _.project_name = campaign.name
                    _.selectedPromo = campaign.promotionType.id
                    _.selectedFollower = campaign.followerCategory.id
                    _.campaign_type = campaign.campaignType
                    _.banner = campaign.banner
                    
                    var file = { size: 123, name: "cover"};
                    var url = _.banner
                    _.$refs.myVueDropzone.manuallyAddFile(file, url);

                    localStorage.setItem('campaignID', campaign.id)
                })
                .catch(err => {
                    console.log(err.response)
                })
        }
    }
}
</script>